import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

// Create a theme instance.
const Light = createTheme({
  palette: {
    primary: {
      main: '#146053',
      dark: '#1A3342',
      light: '#087DF2',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#53AF42',
      dark: '#011636',
      light: '#0242a1',
      contrastText: '#ffffff',
    },
    background: {
      paper: '#133C64',
      default: '#ffffff',
    },
    error: {
      main: red.A400,
    },
  },
});

export default Light;
