import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Divider } from '@mui/material';
import logo from 'rani_passos/public/assets/images/logo.png';
import logo_icon from 'rani_passos/public/assets/images/logo_icon.png';

export const Footer = () => {
  return (
    <>
      <Divider />
      <Box style={{ backgroundColor: '#133C64' }}>
        <Container maxWidth="lg">
          <Box
            py={4}
            display="flex"
            flexWrap="wrap"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box sx={{ margin: '0px 32px' }}>
              <Box
                component="img"
                src={logo_icon}
                sx={{
                  height: 'auto',
                  width: 60,
                  display: 'flex',
                  color: 'inherit',
                  marginBottom: 1
                }}
              />
              <Box
                component="img"
                src={logo}
                sx={{
                  height: 'auto',
                  width: 120,
                  display: 'flex',
                  color: 'inherit',
                  marginBottom: 2
                }}
              />
              <Typography variant="body2">
                Rani Passos | Todos os direitos reservados
              </Typography>
            </Box>
            {/* <Box component="div" sx={{ margin: '0px 32px' }}> */}
            {/*   <Typography */}
            {/*     color="textSecondary" */}
            {/*     component="p" */}
            {/*     variant="h5" */}
            {/*     gutterBottom={false} */}
            {/*   > */}
            {/*     Informática */}
            {/*     <br /> */}
            {/*     para concursos */}
            {/*   </Typography> */}
            {/*   <p>A estratégia que faltava para você ser aprovado!</p> */}
            {/* </Box> */}
          </Box>
        </Container>
      </Box>
    </>
  );
};
