import React, { useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import axios from 'axios';
import { Api } from '../../shared/services/api/axios-config';
import { useAuthContext } from '../../shared/contexts';

function AutoSignIn() {
  const { isAuth } = useAuthContext();
  if (isAuth) {
    return <Navigate to="/" replace />;
  }
  const { accessToken } = useParams();
  const { loginToken } = useAuthContext();

  useEffect(() => {
    async function signInWithToken() {
      if (!accessToken) {
        console.error('Access Token is missing');
        return;
      }
      try {
        const message = await loginToken(accessToken);
        // Handle successful response
        // e.g., store user data in context or redux store, redirect to dashboard
        if (message && message?.includes('Request failed')) {
          console.log('erro');
        }
        // TODO: Save user data and navigate to the desired page
        return <Navigate to="/" replace />;
      } catch (error) {
        console.error('Error signing in with token:', error);
        // TODO: Handle error, show error message, etc.
      }
    }

    signInWithToken();
  }, [accessToken, loginToken]);

  return <div>Signing you in...</div>;
}

export default AutoSignIn;

